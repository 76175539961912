<template>
  <div>
    <rotate-text>ARTICLES</rotate-text>
    <div class="row justify-content-end">
      <div class="col-md-10">
        <div class="blog-intro">
          <div class="row">
            <div class="col-lg-9 col-md-9">
              <h2 class="blog-header">Articles</h2>
              <div v-show="!sync" class="my-4">Loading contents</div>
              <div
                class="post"
                data-aos="fade-down"
                data-aos-delay="100"
                v-for="post in posts"
                :key="post.id"
              >
                <router-link :to="{ name: 'articles.show', params: { slug: post.slug } }">
                  <a>
                    <p class="blog-title">{{ post.title }}</p>
                  </a>
                </router-link>
                <div class="blog-content">
                  <h5>{{ post.created_at | formatDate }}</h5>
                </div>
              </div>
            </div>
          </div>

          <next-page path="/contact" title="Contact Me" content="Lets Talk Here"></next-page>
        </div>
        <!-- /col-md-10 -->
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  metaInfo() {
    return {
      title: this.title,
      meta: [
        {
          name: "description",
          content: "Hey I'm Benjamin Iduwe, checkout some of my articles",
        },
        {
          name: "og:title",
          content: "Hey I'm Benjamin Iduwe, checkout some of my articles",
        },
      ],
    };
  },
  computed: {
    title() {
      return `Articles - ${this.appConfig.name}`;
    },
    ...mapState(["posts", "sync", "appConfig"]),
  },
};
</script>

<style></style>
